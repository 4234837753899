define([
    'lodash',
    'zepto',
    'prop-types',
    'reactDOM',
    'components',
    'santa-components',
    'skins',
    'stripSlideShow',
    'boxSlideShowSlide/skins/skins.json',
    'componentsCore'
], function (
    _,
    $,
    PropTypes,
    ReactDOM,
    components,
    santaComponents,
    skinsPackage,
    stripSlideShow,
    skinsJson,
    componentsCore
) {
    'use strict';

    const scrollMixin = santaComponents.mixins.scrollMixin;
    const fillMixin = components.mediaCommon.mediaLogicMixins.fill;
    /**
     * @class components.boxSlideShowSlide
     * @extends {core.skinBasedComp}
     */
    const boxSlideShowSlide = {
        displayName: 'boxSlideShowSlide',
        mixins: [
            fillMixin,
            componentsCore.mixins.skinBasedComp,
            scrollMixin,
            componentsCore.mixins.createChildComponentMixin,
            santaComponents.mixins.inlineContentMixin
        ],
        propTypes: {
            style: stripSlideShow.SlideShowSantaTypes.slideStyle,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            shouldHideOverflowContent: PropTypes.bool,
            flexibleBoxHeight: PropTypes.bool,
            onMouseEnter: PropTypes.func,
            onMouseLeave: PropTypes.func,
            parentId: PropTypes.string,
            minHeight: PropTypes.number
        },
        statics: {
            applyCompSpecificDomOnlyPatches(comp, style) {
                $(ReactDOM.findDOMNode(comp)).data('min-height', style.minHeight);
            },
            behaviors: components.mediaCommon.mediaBehaviors.fill
        },
        getSkinProperties() {
            return {
                '': {
                    'data-shouldhideoverflowcontent': this.props.shouldHideOverflowContent && !this.props.flexibleBoxHeight,
                    onMouseEnter: this.props.onMouseEnter,
                    onMouseLeave: this.props.onMouseLeave,
                    'data-flexibleboxheight': this.props.flexibleBoxHeight,
                    'data-parent-id': this.props.parentId,
                    'data-min-height': this.props.style.minHeight
                },
                background: this.createFillLayers(),
                inlineContent: {
                    children: this.getChildrenRenderer()
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.BoxSlideShowSlide', boxSlideShowSlide);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return boxSlideShowSlide;
});
